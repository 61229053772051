import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const memberDetails = [
  {
    id: "jahangir-mohammed",
    drName: "Jahangir Mohammed",
    designation: "Founder/CEO",
    company: "Twinhealth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jahangir_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Twin Health was founded by Jahangir Mohammed, a serial inventor and entrepreneur, with a mission to reverse chronic diseases and help people live healthier and happier lives. Jahangir was the Founder/CEO of Jasper, which he helped build into a global market leader with Internet of Things (IoT) platforms. Currently used by over 10,000 enterprises around the world, Jasper was acquired by Cisco for $1.45B. Prior to Jasper, Jahangir was the Founder/CEO of Kineto Wireless, which pioneered the technologies to enable mobile phones to seamlessly use WiFi for voice and data. The technologies invented at Kineto became the basis for the UMA standard.",
    content2:
      "Jahangir is a prolific inventor with over 80 patents. He was named IoT Technology Pioneer at the World Economic Forum and was recognized for his visionary work and social impact by the Einstein Foundation. He was awarded an Honorary Doctorate by Concordia University, Canada.",
  },
  {
    id: "terry-poon",
    drName: "Terry Poon",
    designation: "Co-Founder/CTO",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/terry_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Terry Poon co-founded Twin Health and drives the vision for technology development. He leads the development of Twin's innovative platform and unique algorithms to improve human metabolic health using IoT, machine learning, and Digital Twin technologies. Prior to Twin, Terry was VP of Engineering at Jasper Technologies, where he built global software engineering teams and served as a lead architect of the company’s IoT cloud platform. In addition, Terry led engineering efforts for Jasper’s launch in the China market, the fastest-growing in the company’s history. Prior to Jasper, Terry held engineering and management positions at Oracle.",
    content2:
      "Terry graduated from MIT with a Master's in Computer Science Engineering and BS degrees in Computer Science and Economics. He holds 10 patents in software and analytics technologies.",
  },
  {
    id: "lisa-shah-md",
    drName: "Lisa Shah, MD",
    designation: "Chief Medical Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/lisa_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Lisa Shah, MD is responsible for US clinical operations, outcomes, and innovation to reverse diabetes and other chronic disease. She has years of hands-on experience in healthcare and innovation, from designing value-based care models to leading the implementation of new care delivery models in the practice setting. Prior to joining Twin, Lisa served as CMO for Advantia Health, Chief Innovation Officer for Sound Physicians, and SVP of Clinical Operations and Network for Evolent Health.",
    content2:
      "Lisa graduated from Duke University with a BA in English and completed medical school at the University of Medicine and Dentistry of New Jersey. At the University of Chicago, she completed her residency in Internal Medicine and Pediatrics, a post-residency health services research fellowship, and a Master's degree in Public Policy. Lisa is a member of Women of Impact in Healthcare and has received multiple awards from the Washington Business Journal, including the annual C-Suite award in 2019.",
  },
  {
    id: "shashank-joshi",
    drName: "Shashank R Joshi",
    designation: "Medical Scientist - Research",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shashank_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shashank Joshi, MD is Twin's chief scientist. As an endocrinologist and medical researcher, Dr. Joshi has extensive experience with the treatment and study of diabetes and chronic disease. Before joining Twin, he was the Chair of the South-East Asian International Diabetes Federation and president of several organizations: the Indian Academy of Diabetes, the Endocrine Society of India, the Association of Physicians of India, and the Research Society for Study of Diabetes in India. Dr. Joshi was a member of the faculty at Grant Medical College and Seth GS Medical College, where he earned his MBBS and MD, respectively. He is a fellow of the American College of Endocrinology, the American College of Physicians, and the Royal College of Physicians (London, Glasgow, and Edinburgh).",
    content2:
      "Dr. Joshi has published more than 800 research publications and Stanford University ranked him in the Top 0.5% of all scientists in General and Internal Medicine.",
  },
  {
    id: "maluk-mohamed",
    drName: "Maluk Mohamed",
    designation: "Co-Founder/VP Research/Head (India)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/maluk_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Maluk co-founded Twin Health and as VP of Research, he leads global trend-studies and guides the development of strategic initiatives based on research outcomes. He also runs operations for the Twin Service in India, including its launch in 2019. Prior to Twin, Maluk founded and served as President and Chairman of the MAM Group of Institutions in Tamil Nadu, India. Under his leadership, the institution grew to 10 colleges, currently educating more than 10,000 undergraduate and graduate students in engineering, architecture, business administration, and nursing.",
    content2:
      "Maluk earned his PhD from the Indian Institute of Technology Madras in the area of distributed mobile computing, and his Master of Engineering degree from National Institute of Technology Tiruchirappalli. He has published 178 research publications in various international journals and conference proceedings.",
  },
  {
    id: "garrick-bernstein",
    drName: "Garrick Bernstein",
    designation: "Chief Financial Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/garrick.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Garrick joined Twin as Chief Financial Officer in April 2021. He is responsible for Strategic Finance, Accounting, Data Intelligence and Analytics, Legal and People Experience. Prior to joining Twin, Garrick helped build One Medical (ticker: ONEM) as CFO and led to the successful sale of a minority investment to the Carlyle Group prior to its subsequent Initial Public Offering.",
    content2:
      "Garrick also helped create and build Healthgrades, whose principal assets were successfully sold to Red Ventures. Garrick graduated magna cum laude from the University of Pennsylvania and was a nationally-ranked member of the Varsity Fencing Team.",
  },
  {
    id: "jen-bealer",
    drName: "Jen Bealer",
    designation: "Chief Legal Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jen_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Jennifer Bealer serves as Twin's Chief Legal Officer. Prior to that she was Executive Vice President, General Counsel and Secretary of Progyny Inc. Before Progyny Jennifer was an Associate at the law firm Ropes & Gray’s nationally-ranked healthcare practice from November 2010 to October 2017, where she gained extensive expertise in providing healthcare clients with strategic, regulatory, compliance and transaction advice.",
    content2:
      "Ms. Bealer holds a B.S. in Biology and Psychology from the Pennsylvania State University and received her J.D. from the University of Pennsylvania Law School, A.L.M from Harvard University, and Master of Bioethics from University of Pennsylvania School of Medicine.",
  },
  {
    id: "john-davis",
    drName: "John Davis",
    designation: "Chief Commercial Officer",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/john_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "John is responsible for leading Twin Health’s US commercial strategy and growth. John has 20 years’ experience in healthcare with deep focus on value-based care transformation and risk-based contracting models. Prior to joining Twin, John was an early member of the Evolent Health growth team where he supported Evolent’s growth across Medicare, Medicaid, and commercial market segments resulting in Evolent’s IPO in 2015.",
    content2:
      "John graduated magna cum laude from the University of Georgia with a BBA in International Business.",
  },
  {
    id: "amit-shrivastava",
    drName: "Amit Shrivastava",
    designation: "VP of Products",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/amit.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Amit leads the product and design team at Twin with a focus on innovation and user-centered design that improves human health and happiness. Prior to joining Twin, Amit led mobile product development at Castlight Health. He held senior product, business, and engineering roles at Intel to deliver compelling experiences to global markets. ",
    content2:
      "Amit holds an MBA degree from Babson College and an MS in Computer Science from Texas Tech University.",
  },
  {
    id: "paramesh-s",
    drName: "Paramesh S",
    designation: "Medical Director - Research (RCT)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/parmesh_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Over 30 years of expertise in Diabetology, Run 100+ clinical trials, Broadly published in medical journals",
    content2: "",
  },
  {
    id: "senthil-kumaran",
    drName: "Senthil Kumaran",
    designation: "Head India Finance",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/senthil_v1.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Senthil as a Chartered Accountant & graduate in Commerce from Madras University, alumni from Frankfurt School of Finance and Management, Senthil has more than 24 years of overall broad experience in leading teams in functions such as Finance, Legal, Human Resources, Supply Chain and Project Management in multiple industries across organisations as Nike, Norma Group,FCI, and Praxair with stints in India and Europe.",
    content2:
      "Moved back to pursue his entrepreneurial passion as Co-Founder of online gifting of precious jewels in the 100 $ segment named Zomint ,Worked as CFO for Cuemath a leading after school math learning venture, heading Finance, Human Resources and inorganic acquisitions. Senthil was CFO for Fitness & Digital business division of Curefit & Curefoods, cloud kitchen aggregator for 3 years with strong experience in Funds raising,Investor Relations etc., and been part of a number of inorganic brand acquisitions. Recently as Chief Strategy Officer with Akshayakalpa, Dairy FMCG, helped in raising funds and acquiring new businesses.",
  },
  {
    id: "abhijeet-shahi",
    drName: "Abhijeet Shahi",
    designation: "Associate Director-Marketing",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/abhijeet_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Over 12 years of experience across B2B & B2C marketing, sales growth, customer success and product management roles in medtech & healthcare ecosystems. Strong background of helping early stage organisations scale towards impact & structure. Exposure to a range of responsibilities from marketing strategy including data analytics, content marketing, product management, & partnership initiatives. Experience in consultative selling with the ability to deliver against planned KPIs & revenue targets.",
    content2: "",
  },
  {
    id: "sana-khan",
    drName: "Sana Khan",
    designation: "Director-Growth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/sana@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Sana Khan is responsible for expanding the reach of Twin Health across key sectors in India to make personalised wellness widely accessible.She brings a unique skill set to the organisation; a blend of commerce, effective communication and a result-oriented approach.Sana has over 15 years of experience in Broadcast news in India. She actively helped set up national and international news channels like WION, Rajya Sabha television, and India Ahead.",
    content2:
      "With her strong understanding of news and keen interest in national and international politics she has worked as a prime-time anchor, special reporter and headed Editorial teams across big networks across India like TV Today and INX media.Apart from her time in the business of news, Covid pandemic and her father's long-covid thrusted Sana into uncharted territory. She spearheaded management of her father's hospital as he recovered, and subsequently decided to pursue her passion for healing patients through a Diploma in clinical nutrition.Sana graduated from the Delhi University with a degree in Commerce and went on to major in Media management from the School of Convergence in the national capital.",
  },
  {
    id: "ravi-sankar",
    drName: "Dr. Ravi Sankar",
    designation: "Director-Medical",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_ravi.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Ravi Sankar is an accomplished Endocrinologist and leader in the field of Medicine, Diabetes & Endocrinology. Commencing his career with numerous distinctions, prizes and merit certificates during his medical schooling, he had the opportunity to pursue his postgraduate specialist training in GIM, Diabetes and endocrinology and worked in some of the best medical centres in the UK.",
    content2:
      "After relocating back to India, he has been working as a Senior Endocrinologist at Apollo Hospital, Jubilee Hills, Hyderabad.",
    content3:
      "He held prestigious posts such as the National Clinical Lead for APOLLO SUGAR. He is the founder and served as the President of the Association of Clinical Endocrinologists, India. Dr. Ravi Sankar is known for steering and publishing guidelines on Type 2 Diabetes and on Insulin, in the Indian context.",
  },
  {
    id: "mahesh-nambiar",
    drName: "Mahesh Nambiar",
    designation: "Associate Director-Growth",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/mahesh_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Mahesh brings over 15 years of experience in the healthcare, pharma, and life sciences industry, with expertise in business development, marketing, project management, process optimization, scientific support, and research. Prior to Twin health, he has been a part of NMC Healthcare, Strand Life Sciences, HCG group of hospitals, and two start-ups. With a background in drug discovery research and publication under his belt at Piramal group, Mahesh combines industry knowledge with strong interpersonal skills to deliver exceptional results. He is a seasoned professional who excels in driving growth, optimizing operations, and leading teams to success.",
    content2: "",
  },
  {
    id: "suresh-damodharan",
    drName: "Suresh Damodharan",
    designation: "Director (Clinical)",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/suresh.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Damodaran is the consultant, Diabetes & Endocrinology, Sri Ramakrishna Hospital, Coimbatore. He is also an honorary consultant in Derriford Hospital, Plymouth. He has held important positions in various societies and committees most notable being Vice President, Endocrine Society of India, Executive Council member, Endocrine Thyroid Society, Coimbatore and many such positions. He has received many awards in this illustrious career including the “ The Donnell D. Etzweller International Scholar award”.",
    content2: "",
  },
  {
    id: "shubhda-bhanot",
    drName: "Shubhda Bhanot",
    designation: "Chief Diabetes Educator",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shubhda_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shubhda has 23 years of experience in the field of nutrition & diabetes education. At Twin she works as manager growth & trainer. She is also the chief diabetes educator at Max Health Care Delhi, NCR. In the past share has held similar positions in Medanta – The Medicity, Sitaram Bhartia Institute of Science & Research, Apollo clinic, Cradle Hospital & VLCC. She is the executive member of various associations like Delhi Association of Diabetes Educators & has three chapters published on the book “Insulin Therapy – Current Concepts”.",
    content2: "",
  },
  {
    id: "praveena-suresh",
    drName: "Praveena Suresh",
    designation: "Associate Director-Learning & Development",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/praveena_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Praveena has over 17 years of experience in the field of learning & development. In Twin Health, she has been responsible for the learning and development efforts and most recently has taken the responsibility of sales conversion too. As an individual she is a self-motivated, certificated diabetes educator and professional diabetes management trainer with a passion for educating health care professionals.",
    content2: "",
  },
  {
    id: "ayesha-tabbassum-r",
    drName: "Ayesha Tabbassum R",
    designation: "Associate Director-Coach Operations",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ayesha_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "As an Associate Director of Operations at Twin Health India, I am passionate about empowering individuals to lead healthy and happy lives. With a Master of Philosophy in Nutrition, I bring a strong foundation in the science of nutrition and its impact on overall well-being. I am committed to delivering exceptional service, inspiring positive lifestyle changes, and fostering long-term client success.",
    content2: "",
  },
  {
    id: "vaishnavi-reddy",
    drName: "Vaishnavi Reddy",
    designation: "Associate Director-Clinical Operations",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/vaishnavi_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Vaishnavi Reddy, a highly accomplished leader within our startup, brings a wealth of expertise working with the Market leaders and trend -setters Drawing from her extensive knowledge across diverse Healthcare Industries, Dr.Vaishnavi leads our Clinical operations team with a unique blend of vision and practicality. She is passionate about leveraging technology to revolutionize traditional business models and propel our company into the future. Through her strategic guidance, she inspires our team to challenge boundaries, experiment with new ideas, and push the envelope of innovation. She believes in empowering individuals, encouraging them to take ownership of their ideas, and nurturing their professional growth.",
    content2: "",
  },
  {
    id: "asan-abdul",
    drName: "Asan Abdul",
    designation: "Associate Director-Customer success",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/asan_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Asan Abdul Khadhar, Associate Director of Customer Success, joined Twin Health in March 2023 and is in charge of providing a remarkable and faultless member experience from onboarding until a member is discharged. Handling all member-related grievances and providing on-time service. Asan, a Chennai native and MBA graduate, spent more than 20 years in Abu Dhabi working as a cost controller for Etihad Airport Services, a division of Etihad Airways. Expert in business processes with strong skills in supply chain, material management, accounting, marketing, and cost control.",
    content2: "",
  },
  {
    id: "ankit-mishra",
    drName: "Ankit Mishra",
    designation: "Product Director",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ankit_new.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Ankit holds an engineering degree from BITS Pilani and an MBA from IIM Bangalore, accumulating a decade of diverse professional experience. Throughout his career, he has made significant contributions to the flourishing startup ecosystem in India, engaging with both unicorns and early-stage ventures. His journey began at Ola, where he tackled complex challenges in the Ops-tech industry, such as launching new business models, customer acquisition, loyalty-building, and scaling operations.",
    content2:
      "Subsequently, he joined Swiggy's strategy team, spearheading impactful projects to enhance profitability. At FalconX, a crypto institutional brokerage startup, he led operations and product setup for Asian clients. While there, he seized the opportunity to co-found a direct-to-consumer healthy food brand focused on dried fruits and nuts, unfortunately disrupted by the Covid-19 pandemic. Currently, Ankit has been with Twin for nearly three years, leading the India Product roadmap for Twin Health, where their mission is to leverage AI for the reversal of chronic diseases.",
  },
  {
    id: "sharath-reddy",
    drName: "Sharath Reddy",
    designation: "Engineering Director",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/sharath.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Sharath joined Twin as Engineering Director in Feb 2021 , he leads the Engineering team at Twin India. Prior to joining Twin, Sharath led product development at Amazon , Target Retail , and Motorola Solutions.",
    content2:
      "Sharath graduated from JNTU Hyderabad  with a Master's in Computer Science Engineering",
  },
  {
    id: "toby-cosgrove-md",
    drName: "Toby Cosgrove, MD",
    designation: "Former CEO",
    company: "Cleveland Clinic",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/toby_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Toby Cosgrove, MD is the former President and Chief Executive Officer of Cleveland Clinic and currently serves as an Executive Advisor for the health system.",
    content2:
      "Dr. Cosgrove is a sought-after speaker worldwide. He has addressed the World Economic Forum Annual Meeting at Davos, Switzerland, and the Senate Health, Education, Labor and Pensions Committee, in Washington, D.C. He is regularly quoted and featured in national magazines and newspapers, including a cover story in Time, and major articles in Newsweek, the New York Times, and the Washington Post. He has appeared on CNN, Fox, MSNBC, NBC, CBS, “The Charlie Rose Show” on PBS, and other national media outlets.",
    content3:
      "The recipient of Cleveland Clinic’s Master Clinician Award, Innovator of the Year Award, and Lerner Humanitarian Award, Dr. Cosgrove is also a member of Cleveland Medical Hall of Fame and Cleveland Business Hall of Fame. In 2007 he was named Cleveland Business Executive of the Year by the Sales and Marketing Executives of Cleveland, and Castle Connolly’s National Physician of the Year. He also received the Woodrow Wilson Center Award for Public Service as well as Harvard Business School’s Award from HBS Alumni, Cleveland, and the Humanitarian Award of the Diversity Center of Northeast Ohio. Dr. Cosgrove topped Inside Business’s “Power 100” listing for Northeast Ohio, and is highly ranked among Modern Healthcare’s “100 most powerful people in healthcare” and “most powerful physician executives.”",
  },
  {
    id: "mohit-bhatnagar",
    drName: "Mohit Bhatnagar",
    designation: "Managing Director",
    company: "Sequoia India",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/mohit_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "As a Managing Director with Sequoia India, Mohit focuses on high growth companies in the Payments, Consumer Internet, and SaaS sectors. He has served on the boards of Prizm Payments, Citrus Payments, Ujjivan Microfinance, Freshworks, Zomato, Circles.life, Near, DailyHunt, and One Assist, to name a few.",
    content2:
      "Prior to joining Sequoia, he was Senior Vice President at Bharti Airtel. An entrepreneur at heart, Mohit co-founded Bright pod, a wireless start-up in the US, in the year 2000, by orchestrating a spin off from Ericsson Mobile Systems. Mohit has an MBA from UNC Chapel Hill and an MSEE from Virginia Tech.",
  },
  {
    id: "caroline-xie",
    drName: "Caroline Xie",
    designation: "General Partner",
    company: "ICONIQ",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/xie_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Caroline Xie is a General Partner at ICONIQ investing in software, healthcare, and internet companies. Prior to ICONIQ, Caroline covered financial institutions and healthcare for Morgan Stanley in Hong Kong and California.",
    content2:
      "Caroline’s investments include Articulate, Benchling, Braze, Datadog (DDOG), GitLab, GoodRx (GDRX), Guild Education, Komodo Health, Moveworks, Nayya, QGenda, Reify Health, Relativity, Restaurant365, Sprinklr (CXM), Twin Health, Unite Us, and others.",
  },
  {
    id: "bruce-dunlevie",
    drName: "Bruce Dunlevie",
    designation: "Founder/General Partner",
    company: "Benchmark Capital",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/bruce_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Bruce Dunlevie has worked in the early stage technology venture capital industry for more than 30 years, and is a founder and general partner of Benchmark Capital, based in Silicon Valley. Benchmark was an early investor in numerous successful startups including eBay, Twitter, Uber, Snapchat, WeWork and Instagram. Prior to co-founding Benchmark Capital, Mr. Dunlevie worked in investment banking at Goldman, Sachs & Co.",
    content2:
      "Mr. Dunlevie serves on the board of the Stanford Management Company, the entity which oversees the Stanford University Endowment, and he is a trustee of The Getty Trust in Los Angeles. He is a former trustee of Stanford University and of his alma mater, Rice University. He is also a Fellow Benefactor of Trinity College, University of Cambridge.",
    content3:
      "Mr. Dunlevie earned a BA in literature and history from Rice University and an MBA from Stanford University, where he was an Arjay Miller Scholar.",
  },
  {
    id: "kevin-johnson",
    drName: "Kevin Johnson",
    designation: "Former CEO/President",
    company: "Starbucks",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/kevin_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Kevin Johnson most recently served as president and chief executive officer of Starbucks. Kevin first joined Starbucks in 2009, serving on the company’s board of directors during a period of record-setting transformation and growth. In March 2015, Kevin took on the role of president and chief operating officer, leading the company’s global operating businesses across all geographies, as well as the core support functions of Starbucks supply chain, marketing, human resources, technology and mobile and digital platforms. He assumed the role of president and CEO in April 2017, a role he held for five years prior to retiring in April 2022. Under Kevin’s leadership, Starbucks created significant value for all stakeholders. Kevin was the architect of Starbucks Growth at Scale agenda, transforming the way Starbucks drove innovation both in stores and digitally, expanding its global reach, significantly improving the company’s performance, and delivering record results.",
    content2:
      "His career spans more than 40 years, scaling global businesses, including 16 years at Microsoft, five years as CEO of Juniper Networks, 13 years at Starbucks including five years as president and CEO. He has also served our country when in 2008, he was appointed to the National Security Telecommunication Advisory Committee where he served both presidents George W. Bush and Barack Obama.",
    content3:
      "Kevin’s commitment to innovation and following his heart extends beyond the world of business. He was a founding board member of NPower, an organization that provides nonprofits with access to technology and the skills needed to fulfill their social missions. He is involved with Catalyst, a leading nonprofit focused on expanding opportunities for women in business. Kevin also supports Youth Eastside Services, one of the largest providers of youth and family counseling in the Seattle area. At Starbucks, Kevin is passionate about continuing to nurture what’s at the heart of the company’s success: human connection – the tens of millions of interactions large and small that happen between partners and customers every week, and make a meaningful difference in the communities around the world where Starbucks does business. He graduated from New Mexico State University with a bachelor’s degree in Business Administration.",
  },
  {
    id: "francine-r-kaufman-md",
    drName: "Francine R. Kaufman, MD",
    designation: "Chief Medical Advisor",
    company: "Senseonics",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/francine_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Francine R. Kaufman, MD is the Chief Medical Officer of Senseonics, Inc. (2019-present). Previously, she was the CMO and VP of Global Medical Affairs at Medtronic Diabetes (2009-2019). She is a Distinguished Professor Emerita of Pediatrics at the Keck School of Medicine of the University of Southern California. After joining the Division of Endocrinology at Children’s Hospital Los Angeles in 1980, she served as Director of Diabetes (1990-2001) and then Division Head of Endocrinology (2001-2009). She has authored over 290 peer-reviewed and invited articles, and numerous books including Diabesity (Bantam, 2005), The Medical Management of Type 1 Diabetes (ADA, 2008), The Insulin Pump and Continuous Glucose Monitoring Book: For Patients, Parents and Caregivers (ADA, 2012, 2017) and Rhythms (a novel) (CKI Publisher, 2015).  ",
    content2:
      "She has received numerous grants, including being funded by the NIH for over 3 decades. She was elected to the National Academy of Medicine in 2005. Dr. Kaufman was national president of the ADA (2002-2003). She directed diabetes camps in Southern California, Ecuador, and Haiti for over 30 years. She was instrumental in banning sodas from LA Unified School District in 2004.",
  },
  {
    id: "osama-hamdy-md",
    drName: "Osama Hamdy, MD, PhD",
    designation: "Director Inpatient Diabetes Program",
    company: "Joslin Diabetes Center",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/osama_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Osama Hamdy, MD, PhD is a senior endocrinologist and Medical Director of the Joslin Obesity Clinical Program, and Director of the Inpatient Diabetes Program at Joslin Diabetes Center. He is also an Associate Professor of Medicine at Harvard Medical School. He is a world recognized expert and thought leader with 34 years of extensive clinical experience in nutrition and obesity research.",
    content2:
      "His lab led to innovations in how we manage obesity in patients with type 1 or type 2 diabetes. Dr. Hamdy’s research discovered that 7% weight loss in obese patients with and without diabetes significantly improves vascular endothelial function, insulin sensitivity and markers of inflammation. Dr. Hamdy was a co-investigator in landmark studies: the “Diabetes Prevention Program” and “the Look AHEAD Study.” Dr. Hamdy founded the “Weight Achievement and Intensive Treatment” (Why WAIT) program, helping patients with diabetes to be less dependent on diabetes medications. Dr. Hamdy co-chaired the task force that developed the global transcultural Diabetes Nutrition Algorithm (tDNA). His research led to changes in the standards of diabetes care and lifestyle management by the American Diabetes Association and the American Association of Clinical Endocrinologists.",
    content3:
      "Dr. Hamdy received the Michaela Modan Award of the American Diabetes Association and the Compassionate Caregiver Award of the Kenneth Schwartz Center. He has more than 150 peer-reviewed original articles, reviews and book chapters. He is also the author of the Harvard Health Publication “The Diabetes Breakthrough” reversing diabetes through weight management. He is on the editorial review board of many medical journals including Lancet, JAMA, British Medical Journal, Diabetes Care and Current Diabetes Report.",
  },
  {
    id: "ambrish-mithal-md",
    drName: "Ambrish Mithal, MD",
    designation: "Chairman and Head of Endocrinology & Diabetes",
    company: "Max Healthcare",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ambrish_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Ambrish Mithal, MD is an endocrinologist and the Chairman and Head of Endocrinology and Diabetes at Max Healthcare, Saket, New Delhi; he started working in December 2019. One of the most renowned names in the field of Endocrinology, Dr. Ambrish Mithal is considered to be a thought leader in the medical community. Before joining Max Healthcare, he was Chairman, Division of Endocrinology and Diabetes, at Medanta the Medicity. There, he led the Endocrinology and Diabetes division from 2009 to 2019. Before that, he worked at Apollo Hospital, New Delhi, and Sanjay Gandhi Post Graduate Institute of Medical Sciences, Lucknow.",
    content2:
      "More recently, in 2019, Dr. Mithal has been nominated to the Governing Council of the National Health Authority, the apex body involved in implementing the ambitious population health coverage plan of the government. He has also been appointed (honorary) President, All India Institute of Medical Sciences, Gorakhpur.",
    content3:
      "Dr. Mithal was awarded the International Osteoporosis Foundation President's Award in March 2016. In March 2017, Dr. Mithal was awarded the Dr. B C Roy Award by the President of India for his contribution to the development of endocrinology in India. He has won numerous awards from medical and social bodies throughout his career.",
  },
  {
    id: "elbert-huang-md",
    drName: "Elbert Huang, MD",
    designation: "Director of the Center",
    company: "Chronic Disease Research",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/elbert_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Elbert Huang, MD is Professor of Medicine and Public Health Sciences, Director of the Center for Chronic Disease Research and Policy, and Associate Director of the Chicago Center for Diabetes Translation Research at the University of Chicago. From 2010-2011, he served as a Senior Advisor in the Office of the Assistant Secretary for Planning and Evaluation, Department of Health and Human Services.",
    content2:
      "Dr. Huang is a general internist who studies clinical and health care policy issues at the intersection of diabetes, aging, and health economics. His main research focus is in the area of medical decision making for elderly patients with type 2 diabetes where uncertainty exists regarding how to best individualize diabetes treatments based on clinical parameters and patient preferences. Over the past two decades, Dr. Huang has established one of the most active research programs in geriatric diabetes in the country.",
    content3:
      "Dr. Huang’s research has been supported by the National Institutes of Health, the Centers for Disease Control and Prevention, the American Diabetes Association, and the Juvenile Diabetes Research Foundation. He has received numerous honors including the Research Paper of the Year Award from the Society of General Internal Medicine and elected membership to the American Society for Clinical Investigation. Dr. Huang received his AB, MD, and MPH from Harvard University and came to the University of Chicago in 2001.",
  },
  {
    id: "rohit-satoskar-md",
    drName: "Rohit Satoskar, MD",
    designation: "Director of Medical Services",
    company: "MedStar Georgetown Transplant Institute",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rohit_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Rohit Satoskar, MD, is the Director of Medical Services for the MedStar Georgetown Transplant Institute. He is also medical director of Liver Transplantation and Associate professor in the Department of Medicine at the MedStar Georgetown Transplant Institute. An expert in hepatology and liver transplantation, Dr. Satoskar is board-certified in Internal Medicine, Gastroenterology and Transplant Hepatology. He is widely published and a frequent lecturer, regionally and nationally. In addition to Dr. Satoskar's role as a clinician, teacher and researcher, he is actively involved in public policy efforts to benefit patients with liver disease. ",
    content2:
      "He serves in leadership positions with professional societies such as the American Association for the Study of Liver Diseases and the American Liver Foundation and is a founding board member of the Global Liver Institute dedicated to collaboration and innovation in dealing with liver disease. Dr. Satoskar is highly rated by patients for explaining things well, listening carefully, and treating patients with respect, among other things. He believes in personalized care for each patient to address their particular needs and help achieve the best possible outcome and quality of life. Each patient is cared for as an individual, using the latest evidence-based medicine and technology available.",
  },
  {
    id: "jeffrey-mechanick-md",
    drName: "Jeffrey Mechanick, MD",
    designation: "Director of Metabolic Support",
    company: "Icahn School of Medicine at Mount Sinai",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/jeffrey_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Mechanick is a Professor of Medicine and Medical Director of The Marie-Josée and Henry R. Kravis Center for Cardiovascular Health at Mount Sinai Heart, and Director of Metabolic Support in the Division of Endocrinology, Diabetes and Bone Disease, Icahn School of Medicine at Mount Sinai. He received his M.D. degree from the Icahn School of Medicine at Mount Sinai, completed Internal Medicine residency at Baylor College of Medicine, and completed Endocrine Fellowship at The Mount Sinai Hospital. Dr. Mechanick authored over 386 publications in Endocrinology, Metabolism, and Nutrition Support with 272 PubMed citations currently.",
    content2:
      "He is the 2016-2017 Past President of the American College of Endocrinology, 2013-2014 Past President of the American Association of Clinical Endocrinologists, and 2005-2006 Past President of the American Board of Physician Nutrition Specialists. Dr. Mechanick co-edited Nutritional Strategies for the Diabetic and Prediabetic Patient; Power of Prevention: The Complete Guide to Lifelong Nutrition; Thyroid Cancer: From Emergent Biotechnology to Clinical Practice Guidelines; Molecular Nutrition – The Practical Guide, Lifestyle Medicine – A Manual for Clinical Practice, and Creating a Lifestyle Medicine Center; and co-authored Diabetes Mellitus Pocket and Nutritional Medicine Pocket.",
    content3:
      "Dr. Mechanick was appointed as a member of the President’s Council on Fitness, Sports and Nutrition (PCFSN) Science Board for 2010-2013 and was the 2013-2015 Editor-in-Chief and is currently Editor-in-Chief Emeritus of the PCFSN quarterly publication Elevate Health. Dr. Mechanick chairs the Physician Engagement Committee of the American Association for Parenteral and Enteral Nutrition. Dr. Mechanick also chairs the International Transcultural Diabetes Nutrition Algorithm Working Group. Dr. Mechanick currently serves as Chair of the Board of Visitors for the College of Computer, Mathematics, and Natural Sciences at the University of Maryland at College Park and was the 2011 recipient of the University of Maryland Industry Impact Award and 2011 University of Maryland Biology Alumnus Award. Dr. Mechanick’s research interests are in the fields of nutrition and metabolic support, obesity and lifestyle medicine, and network analysis of complex systems. Dr. Mechanick is responsible for training cardiology fellows at Mount Sinai Heart in preventive cardiology.",
  },
  {
    id: "anoop-misra",
    drName: "Anoop Misra",
    designation: "Medical Advisor",
    company:
      "Chairman - CoE -Diabetes,Metabolic & Endocrinology Fortis Healthcare",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/anoop_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr Misra is Chairman, Fortis-CDOC Center of Excellence for Diabetes, Metabolic Diseases, and Endocrinology, New Delhi, and Director, National Diabetes, Obesity and Cholesterol Foundation, and Diabetes Foundation (India). After doing undergraduate and postgraduation from All India Institute of Medical Sciences, New Delhi, he has worked in Royal Free Hospital in London, UK and at Department of Medicine and Endocrinology at Southwestern Medical Center at Dallas, Texas, USA as WHO Fellow and later, as a faculty. In India, Dr Misra has been participant of top Advisory Committees relating to Diabetes, Cardiovascular diseases, and Nutrition in India. He has been advisor to Ministry of Health, Indian Council of Medical Research and Department of Biotechnology on several issues related to diabetes and other non-communicable diseases.",
    content2:
      "Prof. Misra has more than 40 years of experience in teaching, service, research, and community health intervention programs and has published more than 350 scientific papers/chapters in the National and International journals (citations till Feb, 2023; ~ 39, 181, h-index 100 and i10 index: 410) and books. In 2021, an analysis of Stanford University, he is among top 2% of scientists in diabetes research from India to be ranked globally.",
    content3:
      "He is Editor-in-Chief of “Diabetes and Metabolic Syndrome: Clinical Research and Reviews” (Elsevier), and Clinical Keys (Elsevier), and Associate Editor of “Journal of Diabetes” (USA), and European Journal of Clinical Nutrition (UK). He is member, member Editorial Advisory Board of British Medical Journal, member, BMJ’s Regional Advisory Board for South Asia and member, Lancet Obesity Commission, 2023. Dr Misra has received numerous awards and 31 named orations. He has been awarded highest award for medicine in India Dr BC Roy award (2006) and India’s prestigious National Honor, Padma Shree (2007). He has received “Outstanding Investigator Award” from World India Diabetes Foundation in 2013. His current interest include research on nutrition, metabolism, and diabetes in relation to liver fat, pancreatic fat, lean skeletal muscle mass and vitamin D.He believes that everyone with diabetes should have access to quality care and that obesity should be treated as a chronic disease. He is committed to finding new ways to prevent and treat these conditions and to improving the lives of people affected by them.He is author of bestselling books “Diabetes with Delight” and “Diabetes ke Saath Khushaal Jeevan” (Bloomsbury).",
  },
  {
    id: "ashutosh-shukla",
    drName: "Dr. Ashutosh Shukla",
    designation: "Co-Founder & Medical Director",
    company: "Prana Integrative Medicine Institute",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ashutosh_shukla.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Ashutosh Shukla is the Medical Advisor to Max Healthcare & Senior Director Internal Medicine with over 30 years of post M.D. (Medicine) work experience at top corporate hospitals of Delhi NCR. His portfolio at Max includes managing a variety of complex cases in the OPD, IPD and ICU. He leads a team of 150 doctors in developing and overseeing safety and quality standards for better patient outcomes. Dr. Shukla treated and managed more than 4000 patients with Covid-19 and continues to design specialised post Covid recovery plans for his patients.",
    content2:
      "Dr. Shukla is also the Co-Founder & Medical Director of Prana Integrative Medicine Institute. As a pioneer of India’s first holistic healthcare centre, he offers evidence based, personalized care integrating allopathic and alternative methods of treatment to address lifestyle related chronic diseases.",
  },
  {
    id: "banshi-saboo",
    drName: "Dr. Banshi Saboo",
    designation:
      "MBBS, MD, DNB, PhD President, RSSDI Chief Diabetologist & Chairman of Diabetes Care & Hormone Clinic",
    company: "Dia-Care,Ahmedabad",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/banshi@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Banshi Saboo is a name that is synonymous with ‘Diabetologist’. For patients, Dr. Banshi Saboo truly defines why doctors in India are called: The men sent by god. With experience that’s deeper than an ocean and knowledge that’s an encyclopedia in itself, Dr. Banshi Saboo is truly a name to reckon with when it comes to diabetes care. Dr. Banshi Saboo firmly believes that diabetes is just an ailment, that any one of us can fight against if we maintain a proper lifestyle. It’s this positive attitude that he shows to the patients that give them a new lease on life.",
    content2:
      "Dr. Banshi Saboo is a chief Diabetologist and Chairman of the Diabetes Care & Hormone Clinic at Ahmedabad, He had also done a Ph.D. in Phenotyping & Genotyping of young Diabetics in India. He is known for his organizational skills, which were proved when he almost single-handedly carried out the 37th Annual Conference of RSSDI in Ahmedabad in 2009. Someone who knows the threat of diabetes when a person enters this phase, Dr. Banshi Saboo has worked at grass root levels to make sure that diabetes can be caught hold of at an early stage before patients make it part of their life.Dr. Banshi Saboo has conducted various free check-up camps and educational programs just to eradicate this epidemic from our lives. Dr. Banshi Saboo has been in various research projects i.e. Obesity in Children, Epidemiological study of Diabetes in Gujarat (ICMR INDIAN Study), Indian Heart Watch Study, etc. He has presented many scientific papers at many National and International Conferences. He also has many publications in peer-reviewed journals, scientific abstracts & book chapters in his credit.",
  },
  {
    id: "moushin-wali",
    drName: "Dr Moushin Wali",
    designation: "Senior Consultant",
    company: "SIR GANGA RAM HOSPITAL, New Delhi",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/mohan@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Padmashri Professor Dr M Wali has served government of India for 35 years. During this illustrious career he has been a keen researcher astute teacher and a very competent Physician. He has discharged his services at  the Prestigious Ram Manohar Lohia Hospital and post graduate institute which is now Atal Bihari Vajpayee medical College, G B Pant Institute of Cardiothoracic and  vascular sciences and President Estate Clinic where he has served as a full time Physician to President R Venkataraman,Dr Shanker Dayal Sharma and Bharat Ratna Pranab Mukherjee which is very unique  and exemplary  record.Dr Wali has a mind boggling list of awards, distinctions and merit . he has published more than 120 book chapters and papers in reputed journals and textbooks of Medicine and  Cardiology.",
    content2:
      "His thesis on lipid metabolism was adjudged the best. The President of India has bestowed upon him Best Alumnus of Kanpur University.He has been decorated by Bharat Ratna President APJ Abdul Kalam with Padma Shri which is fourth civilian Honour of the country along with Indian Red cross medal for his personal attributes.He has been a member of CCRUM and supervised more than twenty theses in Unani medicine. He always promotes Indian system of Medicine and encourages Unani medicine and it's research.Dr Wali has been twice honoured with best physician by Union Health Minister and has been bestowed with Hakim Ajmal Khan Global award and lifetime achievement award in Unani medicne also. Above all Dr Wali is a very humble person ready to always help less privileged. HE HAS RECENTLY BEEN AWARDED DOCTOR OD SCIENCE FROM GERMANY.",
  },
  {
    id: "rajiv-kovil",
    drName: "Dr Rajiv kovil",
    designation: "MBBS General Physician, Diabetologist",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rajiv_kovil@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Rajiv Kovil is an experienced Endocrinologist in India with 22+ years in the field.",
    content2:
      "He is the Founding Member of United Diabetes Forum, Joint Secretary of National Society for Prevention of Heart Disease & Rehabilitation (NSPHERE), Member of National Editorial Board (2004) Heart Care Journal.He pursued his MBBS (Mumbai) from T N Medical College (Nair Hospital) in 1996, and then did his Post-Graduate Two Year Diploma In Diabetology (Mumbai University) from S L Raheja Hospital in 1999 where he was Gold Medallist in Diabetology.",
  },
  {
    id: "ashok-jhunjhunwala",
    drName: "Dr Ashok Jhunjhunwala",
    designation:
      "Former Professor / IIT Madras & President, IITM Research Park",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/ashok@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Prof. Ashok Jhunjhunwala’s first appointment was in Washington State University, USA from 1979 to 1980. Prof. Jhunjhunwala then joined IIT Madras in 1981 in the Department of Electrical Engineering. Over the last few decades he has looked at the cost and affordability of various components of the telecommunications and the Internet network, especially in the Indian context. He has worked on techniques to enable communication networks to reach remote parts of rural India. In recent times, he has made some significant contributions in Solar-DC and in battery systems for Electric Vehicles. He has guided more than 70 M.S. and Ph.D. students.",
    content2:
      "Over the course of his career Jhunjhunwala has served as Chairman and member of various government committees. He has been on boards of several private companies and educational institutions in the country. He was on the boards of the State Bank of India, Bharat Electronics Ltd, Hindustan Teleprinters Limited, NRDC, Institute for Development and Research in Banking Technology (IDRBT), VSNL and BSNL as well as Tata Communications, Mahindra Electric, Sasken, Tejas Networks, Tata Teleservices Maharashtra Ltd, Intellect and Exicom. As of 2020 he is on the board of Biotechnology Industry Research Assistance Council (BIRAC), Chairman of IIITH and is the Chairman of Technology Advisory Committee of SEBI In 2017 - 18, Jhujhunwala was on sabbatical from IITM, serving as the Principal Advisor to the Ministry of Power in the Government of India, and the Ministry of Railways in the Government of India, New Delhi.",
  },
  {
    id: "rajiv-kumar",
    drName: "Dr Rajiv Kumar",
    designation:
      "Indian economist who had served as the second vice-chairman of the NITI Aayog",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/rajiv@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Rajiv Kumar, former Vice Chairman of NITI Aayog, recently resumed his chairmanship of Pahle India Foundation, a not-for-profit policy think tank, established by him in 2013. Dr. Kumar’s career has spanned academics to government to corporate chambers and multilateral institutions. The wide range of experience, combined with his intellectual depth, as reflected in his substantial publication record, gives him a unique multi-dimensional perspective on the Indian economy. He served in the government as Economic Advisor with the Department of Economic Affairs (Ministry of Finance) during 1991-1995 and took over as Vice Chairman of NITI Aayog in September 2017, with the rank of Cabinet Minister and served until April 2022.",
    content2:
      "His stints with the corporate sector include serving as Chief Economist of the Confederation of Indian Industries (CII) from 2004-2006 and later as Secretary General of the Federation of Indian Chambers of Commerce and Industry (FICCI), 2011-2013. Dr Kumar has a Ph.D. in Economics from Lucknow University (1978) and a D.Phil. from Oxford University (1982). He has been conferred with D.Litt. (honorary causa) by several universities.",
  },
  {
    id: "s-prakash",
    drName: "Dr S Prakash",
    designation: "MD Of Star health Insurance",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/prakash@4x.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr S Prakash, M.S., FRCS (Glasg), FAIS, a passionate Medical Doctor who became Managing Director of a Dominant Health Insurance Company in India- Star Health Insurance. He is the first of its kind to have 2 decades of clinical experience from India & Overseas and close to 15 years of experience in Health Insurance Industry. During the past 14 years of his tenure, he grew from Medical Director, Executive Director, Chief Operating Officer, to Joint Managing Director and now elevated as a Managing Director. His efficiency in contributing to the functional growth of Star Health Insurance is reflected in the GWP growth of USD 3 million in 2007 to USD 1.47 billion in FY22.",
    content2:
      "He is the Chairperson of CII Tamil Nadu Healthcare Panel for consecutive 3 years. Currently, the Convenor of FICCI Tamil Nadu Insurance Panel, Executive Council Member of General Council of General and Health Insurers. Under his leadership the company has received many awards, the noteworthy one being ‘The World Health Day Award of Excellence’ from the Honourable President of India, Shri Pranab Mukherjee.",
  },
  {
    id: "shashank-joshi-medical-board",
    drName: "Shashank R Joshi",
    designation: "Consultant Endocrine and Metabolic Physician",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/shashank_v2.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Shashank Joshi is the President of two important organizations, the Indian Academy of Diabetes and the Association of Physicians of India (API). He was also a former President of the Research Society for Study of Diabetes in India (RSSDI) and the All India Association of Advancing Research in Obesity. He is the former Vice President of the Association of Physicians of India and is now its Advisory Board member. Joshi is involved with the activities of the American Association of Clinical Endocrinology (AACE) and is serving as the Chairperson of its India Chapter. Shashank Joshi is involved in evidence based research on Endocrinology with special emphasis on Diabetes, Obesity, Thyroid, Osteoporosis and Growth. His studies on the Asian Indian phenotype, co-morbidities in hypothyroidism and the impact of vitamin D deficiency on bone health in Asian Indians are notable. He has also worked as the principal investigator in many teams engaged in multinational clinical trials and has covered two epidemics of diabetes and hypertension in India and Adult Growth Hormone deficiency.",
    content2:
      "Shashank Joshi is a prolific writer of articles on Diabetology and Endocrinology and has published 18 books and monograms and over 600 research publications on topics such as Diabetes, Obesity, Thyroid disorders, Osteoporosis, and Growth. He is also the Editor of Practical Medicine, a popular undergraduate Indian text book. He is also the Editor Emeritus of the Journal of the Association of Physicians of India and has been the Editor of three prominent Indian journals namely Indian Journal of Obesity, Indian Journal of Endocrinology and Metabolism and Indian Journal of Clinical Pharmacology and Therapeutics. Dr. Joshi founded the Indian Journal of Critical Care Medicine and was its Executive Editor. Shashank R Joshi was honoured by the Government of India by awarding him the Padma Shri, in 2014, in recognition of his efforts to the cause of medicine. He is also a recipient of the International Clinician Award, received at the 21st American Association of Clinical Endocrinologists (AACE) Annual Scientific and Clinical Congress, Philadelphia. This is an annual award given to practicing endocrinologists, outside the US. Pharma Leaders in the year 2013 conferred Medical Expert of the Decade at 6th Annual Pharmaceutical Leadership Summit founded by Satya Brahma. Indian Affairs India Leadership Conclave 2014 edition declared Dr. Shashank Joshi as Healthcare Visionary of the Year.",
  },
  {
    id: "krishna-g-seshadri-advisor",
    drName: "Dr. Krishna G Seshadri",
    designation: "Senior Consultant Endocrinology and Diabetes at Apollo Hospitals",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_krishna.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Krishna G Seshadri, an esteemed Endocrinologist with over 22 years of experience, practices at Apollo Hospitals Greams Road in Chennai. Dr. Seshadri, who holds an AB certification, specializes in a diverse array of endocrinology conditions. His expertise encompasses male hypogonadism, osteomalacia, glucocorticoid remediable aldosteronism, growth hormone deficiency, acute diarrhoea treatment, parathyroid diseases, pituitary diseases including pituitary dwarfism, Hba1c monitoring, ketosis-prone diabetes mellitus, pseudohypoparathyroidism, adrenal masses, alkaptonuria, menarche-related issues, Turner syndrome, familial hyperinsulinemic hypoglycemia, combined lipase deficiency, colloid goiter, and type 1 diabetes treatment. Dr. Seshadri offers comprehensive services to address these varied endocrinological concerns, ensuring high-quality care for his patients.",
    content2:
      "If you're seeking Endocrinologist in your neighbourhood then, Dr. Krishna G Seshadri is the right choice for expert care and treatment of your Endocrinology needs. Book an appointment today and experience top-notch medical care.",
  },
  {
    id: "manoj-chawla-advisor",
    drName: "Dr Manoj Chawla",
    designation: "Diabetologist, Executive Member, RSSDI",
    company: "",
    drImage: (
      <StaticImage
        src="../../images/aboutUs/dr_manoj.png"
        alt="Founder"
        placeholder="blurred"
      />
    ),
    content1:
      "Dr. Manoj Chawla is a Diabetologist. He has 20 years of experience in this field.He did MBBS from University of Mumbai, Mumbai in 1997 and a Diploma in Diabetes from University of Mumbai, Mumbai in 1999.He is a member of the Maharashtra Medical Council, India representative at IDF General Assembly. Dubai 2011, Postgraduate lecturer for CPS-Diabetology courses conducted 6 months at CPS, Mumbai, and Chairperson at the 1st Medtronic CGMS and Insulin Pump Conference, Chennai. He is also the Director and Consultant Diabetologist at Lina Diabetes Care & Mumbai Diabetes Research Centre (Andheri-West) and the Founder and President of United Diabetes Forum.",
    content2:
      "He was recently awarded the `Best Consultant Diabetologist in Mumbai` Award, in appreciation of his excellence in the field of Diabetes. Dr. Chawla has co-authored and presented research papers at various national and international symposia. He has been the Principal Investigator for various global clinical trials for new antidiabetic drugs and his special interest areas are CGMS and Insulin Pumps.",
  },
];
